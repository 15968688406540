import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ARCHIVE_SORTER_KEY,
  OVERVIEW_SORTER_KEY,
  SortDirection,
  SortState,
} from "../";
import { FilteringData } from "../interfaces/filteringData";
import { parseActiveSorterFromSessionStorage } from "../utils/sessionStorage";
import { SortStateAction } from "../interfaces";
import { MetricsDatePeriod } from "../../../analytics/constants";
import { OverviewType } from "../../../app";

export type FilterArrayAction = {
  key: string;
  value: string;
  delimiter: string;
  overviewType?: OverviewType | null;
};

export type MetricsDateAction = {
  startDate: string;
  endDate: string;
};

interface SortersState {
  overviewSorter: SortState | null;
  archiveSorter: SortState | null;
}

interface FiltersState {
  overviewFilterArray: Array<string>;
  archiveFilterArray: Array<string>;
  filterString: string;
}

interface DataRefiningState extends SortersState, FiltersState {
  filteringData: FilteringData;
  metricsFilter: MetricsFilterState;
  shouldInitFilterString: boolean;
}

interface MetricsFilterState {
  filterString: string;
  filterArray: Array<string>;
  startDate?: string;
  endDate?: string;
  isBySource: boolean;
  activePeriod: MetricsDatePeriod;
  inputIds: Array<string>;
}

const filterString = (storageKey: string): string => {
  const storageItem = localStorage.getItem(storageKey);
  if (storageItem) {
    return storageItem;
  }

  return "";
};

const initialState: DataRefiningState = {
  shouldInitFilterString: false,
  overviewSorter: parseActiveSorterFromSessionStorage(
    OVERVIEW_SORTER_KEY,
    SortDirection.Ascending
  ),
  archiveSorter: parseActiveSorterFromSessionStorage(
    ARCHIVE_SORTER_KEY,
    SortDirection.Descending
  ),
  filterString: filterString("overviewFilters"),
  overviewFilterArray: filterString("overviewFilters")
    .split(",")
    .filter(Boolean),
  archiveFilterArray: filterString("archiveFilters").split(",").filter(Boolean),
  filteringData: {
    assigneeOptions: [],
    senderOptions: [],
    inputOptions: [],
  },
  metricsFilter: {
    filterString: "",
    filterArray: [],
    isBySource: true,
    activePeriod: MetricsDatePeriod.THREE_MONTHS,
    inputIds: [],
  },
};

export const dataRefiningSlice = createSlice({
  name: "dataRefining",
  initialState,
  reducers: {
    setActiveSorter: (state, action: PayloadAction<SortStateAction>) => {
      const { payload } = action;
      if (payload.state) {
        sessionStorage.setItem(
          payload.storageKey,
          `${payload.state.key}:${payload.state.sortDirection}`
        );
      } else {
        sessionStorage.setItem(payload.storageKey, "");
      }

      state[payload.storageKey as keyof SortersState] = payload.state;
    },
    setFilterArrayOverview: (state, action: PayloadAction<Array<string>>) => {
      state.overviewFilterArray = action.payload;
    },
    setFilterArrayArchive: (state, action: PayloadAction<Array<string>>) => {
      state.archiveFilterArray = action.payload;
    },
    updateFilterArray: (state, action: PayloadAction<FilterArrayAction>) => {
      const { value, key, delimiter, overviewType } = action.payload;

      const stateKey =
        overviewType === OverviewType.Archived
          ? "archiveFilterArray"
          : "overviewFilterArray";

      const prevState = state[stateKey];
      if (value) {
        state[stateKey] = [
          ...prevState.filter((item) => item.split(delimiter)[0] !== key),
          `${key}${delimiter}${value}`,
        ];
      } else {
        state[stateKey] = [
          ...prevState.filter((item) => item.split(delimiter)[0] !== key),
        ];
      }
    },
    updateFilterString: (state, action: PayloadAction<OverviewType>) => {
      if (action.payload === OverviewType.Archived) {
        state.filterString = [...state.archiveFilterArray].join(",");
      } else {
        state.filterString = [...state.overviewFilterArray].join(",");
      }
    },
    setFilterString: (state, action: PayloadAction<string>) => {
      state.filterString = action.payload;
    },
    removeStatusFromFilters: (state, action: PayloadAction<OverviewType>) => {
      if (action.payload === OverviewType.Archived) {
        const newArchiveFilterArray = [
          ...state.overviewFilterArray.filter(
            (item) => item.split("=")[0] !== "status"
          ),
        ];
        state.filterString = newArchiveFilterArray.join(",");
        state.archiveFilterArray = newArchiveFilterArray;
      } else {
        const newOverviewFilterArray = [
          ...state.overviewFilterArray.filter(
            (item) => item.split("=")[0] !== "status"
          ),
        ];
        state.filterString = newOverviewFilterArray.join(",");
        state.overviewFilterArray = newOverviewFilterArray;
      }
    },
    clearFilters: (state) => {
      state.filterString = "";
      state.overviewFilterArray = [];
      state.archiveFilterArray = [];
    },
    fetchedFilteringData: (state, action: PayloadAction<FilteringData>) => {
      state.filteringData = action.payload;
    },
    updateMetricsFilterArray: (
      state,
      action: PayloadAction<FilterArrayAction>
    ) => {
      const { value, key, delimiter } = action.payload;
      const prevState = state.metricsFilter.filterArray;
      if (value) {
        state.metricsFilter.filterArray = [
          ...prevState.filter((item) => item.split(delimiter)[0] !== key),
          `${key}${delimiter}${value}`,
        ];
      } else {
        state.metricsFilter.filterArray = [
          ...prevState.filter((item) => item.split(delimiter)[0] !== key),
        ];
      }
    },
    updateMetricsFilterString: (state) => {
      state.metricsFilter.filterString =
        state.metricsFilter.filterArray.join(",");
    },
    updateMetricsDates: (state, action: PayloadAction<MetricsDateAction>) => {
      const { startDate, endDate } = action.payload;

      state.metricsFilter = {
        ...state.metricsFilter,
        startDate: startDate,
        endDate: endDate,
      };
    },
    setMetricsIsBySource: (state, action: PayloadAction<boolean>) => {
      state.metricsFilter.isBySource = action.payload;
    },
    setMetricsDatePeriod: (state, action: PayloadAction<MetricsDatePeriod>) => {
      state.metricsFilter.activePeriod = action.payload;
    },
    setMetricsInputIds: (state, action: PayloadAction<Array<string>>) => {
      state.metricsFilter.inputIds = action.payload;
    },
    setShouldInitFilteringData: (state) => {
      state.shouldInitFilterString = true;
    },
  },
});

export const {
  setFilterString,
  setActiveSorter,
  setFilterArrayOverview,
  setFilterArrayArchive,
  updateFilterString,
  updateFilterArray,
  removeStatusFromFilters,
  clearFilters,
  fetchedFilteringData,
  updateMetricsFilterArray,
  updateMetricsFilterString,
  updateMetricsDates,
  setMetricsIsBySource,
  setMetricsDatePeriod,
  setMetricsInputIds,
  setShouldInitFilteringData,
} = dataRefiningSlice.actions;

export default dataRefiningSlice.reducer;
