import React, { FC } from "react";
import "./MetricGraphs.scss";
import { DocumentSetMetrics } from "../../interfaces/documentSetMetrics";
import LineGraph from "./lineGraph/LineGraph";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import AverageNumber from "./number/AverageNumber";
import MetricsOverview from "../metricsOverview/MetricsOverview";
import SourcesOverview from "../sourcesOverview/SourcesOverview";
import { useAppSelector } from "../../../app";
import calculateMetricsSelector from "../../selectors/calculateMetricsSelector";

type Props = {
  metrics: DocumentSetMetrics | undefined;
  loading: boolean;
  showNoMetricsAlert: boolean;
};

const MetricGraphs: FC<Props> = ({ metrics, loading, showNoMetricsAlert }) => {
  const { t } = useTranslation("analytics");

  const { organizationMetrics, sourceMetrics } = useAppSelector(
    calculateMetricsSelector
  );

  return (
    <div className="metric-graphs__container">
      <MetricsOverview
        totalVolume={organizationMetrics.volume.value}
        totalVolumeTrend={organizationMetrics.volume.trend}
        avgTimeSpend={organizationMetrics.avgTimeSpend.value}
        avgTimeSpendTrend={organizationMetrics.avgTimeSpend.trend}
        automaticallyProcessed={organizationMetrics.automatic.value}
        automaticallyProcessedTrend={organizationMetrics.automatic.trend}
      />
      <SourcesOverview sourceMetrics={sourceMetrics} />
      {showNoMetricsAlert && !loading ? (
        <Alert
          message={t("noMetricsFound")}
          type="info"
          showIcon
          className="graph-alert"
        />
      ) : null}
      <div className="metric-graphs-row">
        <div className="line-graph">
          <LineGraph
            title={t("metricGraphs.firstTimeRight")}
            tooltipTitle={t("tooltips.organizationFirstTimeRight")}
            metrics={metrics?.firstTimeRight}
            loading={loading}
            tooltipSuffix="%"
          />
        </div>
        <div className="pie-graph">
          <AverageNumber metrics={metrics?.firstTimeRight} valueSuffix="%" />
        </div>
      </div>
    </div>
  );
};

export default MetricGraphs;
