import React, { FC } from "react";
import "./MetricsOverview.scss";
import { getClassNameForTrend } from "../../utils/trend";
import { shortEnglishHumanizer } from "../../utils/time";
import { numberAbbreviation } from "../../utils/number";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  totalVolume: number;
  totalVolumeTrend: number;
  avgTimeSpend: number;
  avgTimeSpendTrend: number;
  automaticallyProcessed: number;
  automaticallyProcessedTrend: number;
};

const MetricsOverview: FC<Props> = ({
  totalVolume,
  totalVolumeTrend,
  avgTimeSpend,
  avgTimeSpendTrend,
  automaticallyProcessed,
  automaticallyProcessedTrend,
}) => {
  const { t } = useTranslation("analytics");

  return (
    <div className="metrics-overview__container">
      <div className="metrics-overview__header">
        <h3>{t("metricsOverview.title")}</h3>
        <Tooltip title={t("metricsOverview.tooltip")}>
          <span className="bi bi-question-circle" />
        </Tooltip>
      </div>
      <div className="metrics-overview__data-container">
        <div className="metrics-overview__item">
          <span className="metrics-overview__item-title">
            {t("metricsOverview.volume")}
          </span>
          <span className="metrics-overview__item-value">
            {numberAbbreviation.format(totalVolume)}
          </span>
          <span
            className={getClassNameForTrend(
              totalVolumeTrend,
              "metrics-overview__item-trend"
            )}
          >
            {totalVolumeTrend}
          </span>
        </div>
        <div className="metrics-overview__item">
          <span className="metrics-overview__item-title">
            {t("metricsOverview.avgTime")}
          </span>
          <span className="metrics-overview__item-value">
            {shortEnglishHumanizer(avgTimeSpend * 1000)}
          </span>
          <span
            className={getClassNameForTrend(
              avgTimeSpendTrend,
              "metrics-overview__item-trend",
              true
            )}
          >
            {avgTimeSpendTrend}
          </span>
        </div>
        <div className="metrics-overview__item">
          <span className="metrics-overview__item-title">
            {t("metricsOverview.auto")}
          </span>
          <span className="metrics-overview__item-value">
            {numberAbbreviation.format(automaticallyProcessed)}
          </span>
          <span
            className={getClassNameForTrend(
              automaticallyProcessedTrend,
              "metrics-overview__item-trend"
            )}
          >
            {automaticallyProcessedTrend}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MetricsOverview;
