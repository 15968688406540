import React, { FC, useCallback } from "react";
import useTableUpdater from "../../../../hooks/useTableUpdater";
import { useAppSelector } from "../../../../../app";
import textLayerForPageSelector from "../../../../../annotation/selectors/textlayerForPageSelector";

type Props = {
  visible: boolean;
  tableId: string;
  id: string;
  style: any;
  page: number;
};

const RemoveButton: FC<Props> = ({ visible, tableId, id, style, page }) => {
  const textLayerForPage = useAppSelector((state) =>
    textLayerForPageSelector(state, { page })
  );

  const { deleteColumn } = useTableUpdater();

  const handleClick = useCallback(() => {
    if (!textLayerForPage) {
      return;
    }

    const { tokens, text } = textLayerForPage;

    deleteColumn(tableId, tokens, text, id);
  }, [deleteColumn, textLayerForPage, id, tableId]);

  if (!visible) {
    return null;
  }

  return (
    <span
      className="bi bi-dash-circle-fill table-line__remove-button"
      style={style}
      onClick={handleClick}
    />
  );
};

export default RemoveButton;
