import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { doneStatuses, Status } from "../../common/status/status";
import useBulkRemove from "./useBulkRemove";
import { DocumentSetData } from "../interfaces/documentSet";
import { resetRowsToRemove, updateRemoveRows } from "../store/documentSetSlice";

const useBulkRemoveHeader = (documentSets: Array<DocumentSetData>) => {
  const { rowsToRemove } = useBulkRemove();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.userReducer.user);

  const rowsThatCanBeRemoved = useMemo(() => {
    return documentSets.filter(
      (documentSet) =>
        !doneStatuses.includes(documentSet.status) &&
        (!documentSet.assignee || documentSet.assignee?.id === user?.id) &&
        documentSet.status !== Status.Remove
    );
  }, [documentSets, user]);

  const isAllSelected = useMemo(() => {
    return rowsToRemove.length === rowsThatCanBeRemoved.length;
  }, [rowsToRemove, rowsThatCanBeRemoved]);

  const toggleSelectAll = useCallback(() => {
    if (rowsToRemove.length === rowsThatCanBeRemoved.length) {
      dispatch(resetRowsToRemove());
    } else {
      const ids = rowsThatCanBeRemoved.map((row) => row.id);
      dispatch(updateRemoveRows(ids));
    }
  }, [dispatch, rowsThatCanBeRemoved, rowsToRemove]);

  return {
    rowsToRemove,
    isAllSelected,
    toggleSelectAll,
  };
};

export default useBulkRemoveHeader;
