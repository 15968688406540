import { createSelector } from "@reduxjs/toolkit";
import selectConfigMap from "./configMapSelector";

const flatEntityListSelector = createSelector(selectConfigMap, (configMap) => {
  if (!configMap?.groupBlocks?.length) {
    return [];
  }

  return [...configMap.groupBlocks]
    .sort((a, b) => a.index - b.index)
    .map((block) => ({
      ...block,
      groupBlockEntityTypes: [...block.groupBlockEntityTypes!].sort(
        (a, b) => a.index - b.index
      ),
    }))
    .flatMap((block) => block.groupBlockEntityTypes)
    .filter((entity) => entity.required);
});

export default flatEntityListSelector;
