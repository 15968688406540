import { useState } from "react";

const useRangePickerFocus = () => {
  const [open, setOpen] = useState(true);

  const handleFocus = () => {
    setOpen(true);
  };

  return { open, handleFocus, setOpen };
};

export default useRangePickerFocus;
