import { useCallback, useEffect, useMemo, useState } from "react";
import { OptionType } from "../../metadata/interfaces/classifier";
import { fetchClassifierOptionsSearch } from "../../metadata/utils/classifier";
import { SearchQuery } from "../interfaces/api";
import { useAppSelector } from "../../app";
import { buildSearchQuery } from "../../common/utilities/requests";
import { Input } from "../interfaces/documentSet";
import { useTranslation } from "react-i18next";

const useFilteringSearch = (query: SearchQuery, inputs?: Array<Input>) => {
  const store = useAppSelector((state) => state);

  const [options, setOptions] = useState<Array<OptionType>>([]);

  const {
    i18n: { language },
  } = useTranslation();

  const handleBlur = useCallback(() => {
    setOptions([]);
  }, []);

  const inputIds = useMemo(() => {
    return inputs?.map((item) => item.id);
  }, [inputs]);

  const handleSearch = useCallback(
    async (searchValue: string) => {
      if (!query.prefetch && searchValue.length < 3) {
        return;
      }

      try {
        const searchQuery = buildSearchQuery(
          query,
          searchValue,
          store,
          language,
          inputIds
        );

        const { data } = await fetchClassifierOptionsSearch(
          query.endpoint,
          searchQuery
        );
        setOptions(
          data.map((d) => {
            if (d.visibleValue) {
              return {
                key: d.key,
                value: d.visibleValue,
                label: d.visibleValue,
              };
            }

            return d;
          })
        );
      } catch (e) {
        setOptions([]);
        console.log(e);
      }
    },
    [query, store, inputIds, language]
  );

  const handleFilter = useCallback(
    (input: string, option: any) => {
      if (!query?.prefetch) {
        return true;
      }

      return option.label.toLowerCase().includes(input.toLowerCase());
    },
    [query]
  );

  useEffect(() => {
    if (query.prefetch) {
      handleSearch("");
    }
  }, [query.prefetch, handleSearch]);

  return { options, handleSearch, handleFilter, handleBlur };
};

export default useFilteringSearch;
