import { useCallback, useMemo } from "react";
import { PAGE_OFFSET, SearchPageable } from "../";
import { useAppDispatch, useAppSelector } from "../../app";
import { changeActivePage } from "../../app/store/appSlice";
import { resetRowsToRemove } from "../store/documentSetSlice";

const usePagination = () => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.appReducer.activePage);

  const pageable = useMemo((): SearchPageable => {
    return {
      page: currentPage,
    };
  }, [currentPage]);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(changeActivePage(page - PAGE_OFFSET));
      dispatch(resetRowsToRemove());
    },
    [dispatch]
  );

  return {
    currentPage,
    handlePageChange,
    pageable,
  };
};

export default usePagination;
