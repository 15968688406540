import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchDocumentSetsTrigger,
  removeDocumentSets,
  resetRowsToRemove,
  updateRemoveRow,
} from "../store/documentSetSlice";
import http from "../../common/utilities/HttpModule";
import { Status } from "../../common/status/status";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const useBulkRemove = () => {
  const { t } = useTranslation("notifications");

  const dispatch = useAppDispatch();

  const { rowsToRemove } = useAppSelector((state) => state.documentSetsReducer);

  const user = useAppSelector((state) => state.userReducer.user);

  const toggleRemoveCheckbox = useCallback(
    (id: string) => {
      dispatch(updateRemoveRow(id));
    },
    [dispatch]
  );

  const resetRows = useCallback(() => {
    dispatch(resetRowsToRemove());
  }, [dispatch]);

  const removeRows = useCallback(async () => {
    if (!rowsToRemove.length) {
      return;
    }

    try {
      let url = `api/v1/document-sets/remove?assignee=${encodeURI(
        user!.id
      )}&status=${Status.Remove}`;

      await http.post(url, rowsToRemove);

      dispatch(removeDocumentSets(rowsToRemove));
      resetRows();
      dispatch(fetchDocumentSetsTrigger());

      notification.success({
        key: "deleteToast",
        message: t("documentsRemoved.success.message"),
        description: t("documentsRemoved.success.description"),
      });
    } catch (e) {
      console.error("Failed to remove document sets");

      notification.error({
        key: "deleteToast",
        message: t("documentsRemoved.error.message"),
        description: t("documentsRemoved.error.description"),
      });
    }
  }, [dispatch, rowsToRemove, resetRows, t, user]);

  return {
    rowsToRemove,
    toggleRemoveCheckbox,
    removeRows,
    resetRows,
  };
};

export default useBulkRemove;
