import { EntityAnnotation } from "../../annotation";
import { Annotation } from "../../annotator/interfaces/annotation";

export const getValueAndNormalizedValue = (
  entityAnnotation: EntityAnnotation
) => {
  const value = entityAnnotation?.value ?? "";
  const normalizedValue =
    entityAnnotation?.entityAnnotationNormalization?.normalizedValue;

  return {
    value,
    normalizedValue,
  };
};

export const calcAverageScore = (
  annotationsForEntity: Array<EntityAnnotation | Annotation>
): number => {
  const total = annotationsForEntity
    .map((a) => a.modelScore || 0)
    .reduce((acc, curr) => acc + curr, 0);

  return total / annotationsForEntity.length;
};
