import React, { FC, MouseEvent, useCallback, useMemo } from "react";
import { Checkbox } from "antd";
import useBulkRemove from "../../../../hooks/useBulkRemove";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import { doneStatuses, Status } from "../../../../../common/status/status";
import { useAppSelector } from "../../../../../app";

type Props = {
  documentSet: DocumentSetData;
};

const BulkRemoveCell: FC<Props> = ({ documentSet }) => {
  const { rowsToRemove, toggleRemoveCheckbox } = useBulkRemove();

  const user = useAppSelector((state) => state.userReducer.user);

  const checked = useMemo(() => {
    return rowsToRemove.includes(documentSet.id);
  }, [rowsToRemove, documentSet.id]);

  const handleRemoveClicked = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      toggleRemoveCheckbox(documentSet.id);
    },
    [toggleRemoveCheckbox, documentSet.id]
  );

  const canRowBeDeleted = useMemo(() => {
    return (
      !doneStatuses.includes(documentSet.status) &&
      (!documentSet.assignee || documentSet.assignee?.id === user?.id) &&
      documentSet.status !== Status.Remove
    );
  }, [documentSet, user]);

  return (
    <div className="remove-checkbox">
      <div className={rowsToRemove.length >= 1 ? "visible" : "hover"}>
        {canRowBeDeleted && (
          <Checkbox
            checked={checked}
            className="checkbox"
            disabled={!canRowBeDeleted}
            title={canRowBeDeleted.toString()}
            onClick={handleRemoveClicked}
          />
        )}
      </div>
    </div>
  );
};

export default BulkRemoveCell;
