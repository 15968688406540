import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { Select } from "antd";
import { Option } from "../../../../common/dataRefining/interfaces/filteringData";
import { OverviewType } from "../../../../app";
import { ColumnItem } from "../../../interfaces/overview";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import useFilterableItem from "../../../hooks/useFilterableItem";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import ExtraSelectFilter from "./ExtraSelectFilter";

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  placeholder: string;
  options: Array<Option>;
  mode?: "multiple" | "tags";
  renderRef: MutableRefObject<any>;
  isOpen: boolean;
  overviewType: OverviewType;
};

const FilterableItem: FC<Props> = ({
  columnItem,
  label,
  placeholder,
  options,
  mode = "multiple",
  renderRef,
  isOpen,
  overviewType,
}) => {
  const { selectedFiltersInfo, getValueFromKey } = useTableHeaderFilter(
    columnItem,
    overviewType
  );

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter,
      selectedFiltersInfo
    )?.split("+");
  }, [
    getValueFromKey,
    columnItem?.metadata,
    overviewType,
    selectedFiltersInfo,
  ]);

  const {
    checkboxValue,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { ref, selectValue, handleChange, handleFocus, handleBlur, open } =
    useFilterableItem(
      columnItem,
      overviewType,
      isOpen,
      showBlankEntriesCheckbox,
      blankValue,
      values
    );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={checkboxValue}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <Select
        ref={ref}
        mode={mode}
        open={open}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={selectValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        getPopupContainer={() => renderRef.current}
        onInputKeyDown={handleKeyEvent}
        allowClear
      />
      <ExtraSelectFilter columnItem={columnItem} overviewType={overviewType} />
    </div>
  );
};

export default FilterableItem;
