import { useMemo } from "react";
import useMeasure from "react-use-measure";
import {
  HEIGHT_ANNOTATION_MODE_ALERT,
  HEIGHT_BULK_REMOVE_HEADER,
  HEIGHT_REMOVED_PAGE_ALERT,
  HEIGHT_TABLE_HEADER,
  HEIGHT_TABLE_HEADER_AND_PAGINATION,
} from "../constants";
import { OverviewType, useAppSelector } from "../../app";
import useBulkRemove from "./useBulkRemove";

const useOverviewTableDynamicHeight = (overviewType: OverviewType) => {
  const { numberOfPages } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const [refHeightContainer, { height: heightContainer }] = useMeasure();

  const { rowsToRemove } = useBulkRemove();

  const heightTableContent = useMemo(() => {
    let heightAlerts = isAnnotationMode ? HEIGHT_ANNOTATION_MODE_ALERT : 0;
    heightAlerts += rowsToRemove.length ? HEIGHT_BULK_REMOVE_HEADER : 0;
    heightAlerts +=
      overviewType === OverviewType.Remove ? HEIGHT_REMOVED_PAGE_ALERT : 0;

    if (numberOfPages > 1) {
      return (
        heightContainer - HEIGHT_TABLE_HEADER_AND_PAGINATION - heightAlerts
      );
    }

    return heightContainer - HEIGHT_TABLE_HEADER - heightAlerts;
  }, [
    heightContainer,
    numberOfPages,
    rowsToRemove,
    isAnnotationMode,
    overviewType,
  ]);

  return {
    refHeightContainer,
    heightTableContent,
  };
};

export default useOverviewTableDynamicHeight;
