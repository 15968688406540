import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OptionType } from "../interfaces/classifier";
import {
  fetchClassifierOptions,
  fetchClassifierOptionsSearch,
} from "../utils/classifier";
import {
  changeActiveButton,
  changeActiveEntity,
  changeEditing,
  changeHasBeenEdited,
} from "../../app/store/appSlice";
import {
  CategorizationSetType,
  CategorizationWithConfig,
  ICategorization,
} from "../../configMap/interfaces/category";
import selectDocumentCategoryAnnotationId from "../selectors/selectDocumentCategoryAnnotationId";
import {
  removeDocumentCategoryAnnotation,
  updateDocumentCategoryAnnotation,
} from "../store/metadataSlice";
import useEntityAnnotations from "./useEntityAnnotations";
import { buildSearchQuery } from "../../common/utilities/requests";
import { Status } from "../../common/status/status";
import { InputStatus } from "antd/es/_util/statusUtils";
import { useTranslation } from "react-i18next";

const getInitialOptions = (
  categorization: ICategorization
): Array<OptionType> => {
  if (categorization.setType === CategorizationSetType.DYNAMIC) {
    return [];
  }

  return categorization.categories.map(
    (cat): OptionType => ({
      key: cat.id,
      value: cat.id,
      label: cat.visibleValue,
      visibleValue: cat.visibleValue,
      translation: null,
    })
  );
};

const useCategorization = (
  categorizationWithConfig: CategorizationWithConfig,
  index: number,
  groupBlockId?: string
) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { categorization, required } = categorizationWithConfig;
  const { id, search, setType } = categorization;
  const initialOptions = getInitialOptions(categorization);

  const dispatch = useAppDispatch();

  const document = useAppSelector(
    (state) => state.documentReducer.activeDocument
  );
  const documentSetStatus = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet?.status
  );

  const categoryAnnotation = useAppSelector((state) =>
    selectDocumentCategoryAnnotationId(state, {
      documentId: document?.id,
      categorizationId: id,
      groupBlockId: groupBlockId,
      index: index,
    })
  );

  const normalizeEntityAnnotations = useEntityAnnotations();

  const [options, setOptions] = useState<Array<OptionType>>(initialOptions);
  const [disableTyping, setDisableTyping] = useState(false);

  const tabId = useMemo(() => {
    if (groupBlockId) {
      return `categorization-${id}-${groupBlockId}-${index}`;
    }

    return `categorization-${id}-${index}`;
  }, [id, index, groupBlockId]);

  const selectedCategory = useMemo(() => {
    if (!categoryAnnotation) {
      return null;
    }

    const isFixedType = setType === CategorizationSetType.FIXED;
    if (!isFixedType) {
      return {
        value: categoryAnnotation.categoryValue,
        visibleValue: categoryAnnotation.categoryVisibleValue,
      };
    }

    const found = categorization.categories.find(
      (c) => c.id === categoryAnnotation.categoryId
    );

    if (found) {
      return found;
    }

    return null;
  }, [categoryAnnotation, categorization, setType]);

  const handleChange = useCallback(
    (key: string, callback: () => void) => {
      if (!document?.id) {
        return;
      }

      const found = options.find((o) => o.key === key);

      dispatch(changeHasBeenEdited(true));
      if (found) {
        const isFixedType =
          categorization.setType?.toString() === CategorizationSetType.FIXED;

        let categoryId;
        let categoryValue;
        let categoryVisibleValue;

        if (isFixedType) {
          categoryId = found.value;
        } else {
          categoryId = categorization.categories[0].id;
          categoryValue = found.value;
          categoryVisibleValue = found.visibleValue;
        }

        dispatch(
          updateDocumentCategoryAnnotation({
            documentId: document.id,
            categorizationId: id,
            categoryId: categoryId,
            categoryValue: categoryValue,
            categoryVisibleValue: categoryVisibleValue,
            groupBlockId,
            index,
            isByUser: true,
          })
        );

        normalizeEntityAnnotations(
          categorization,
          found.value,
          index,
          !!groupBlockId
        ).catch(() => undefined);
        callback();
        return;
      }

      dispatch(
        removeDocumentCategoryAnnotation({
          documentId: document.id,
          categorizationId: id,
          groupBlockId,
          index,
        })
      );
      callback();
    },
    [
      document?.id,
      id,
      options,
      groupBlockId,
      index,
      dispatch,
      normalizeEntityAnnotations,
      categorization,
    ]
  );

  const handleTypingChange = useCallback(() => {
    if (!disableTyping) {
      dispatch(changeEditing(tabId));
      dispatch(changeActiveEntity(undefined));
      dispatch(changeActiveButton({ id: tabId }));
      setDisableTyping(true);
      return;
    }

    dispatch(changeEditing(null));
    dispatch(changeActiveButton(undefined));
    setDisableTyping(false);
  }, [dispatch, disableTyping, tabId]);

  const handleHttpRequest = useCallback(
    async (signal: AbortSignal) => {
      if (!search) {
        return;
      }

      try {
        const { data } = await fetchClassifierOptions(id, signal);
        setOptions(data);
      } catch (e) {
        if (signal.aborted) {
          return;
        }

        console.log(e);
      }
    },
    [id, search]
  );

  const handleSearch = useCallback(
    async (value: string) => {
      if (!search) {
        return;
      }

      if (search.prefetch) {
        return;
      }

      if (value.length < 3) {
        return;
      }

      try {
        const { search } = categorization;

        const searchQuery = buildSearchQuery(search, value, null, language, [
          document!.documentSetInputId,
        ]);
        const { data } = await fetchClassifierOptionsSearch(
          search.endpoint,
          searchQuery
        );
        setOptions(data);
      } catch (e) {
        console.log(e);
      }
    },
    [search, categorization, document, language]
  );

  const handleFilter = useCallback(
    (input: string, option: any) => {
      if (setType === CategorizationSetType.DYNAMIC) {
        return true;
      }

      return option.label.toLowerCase().includes(input.toLowerCase());
    },
    [setType]
  );

  const status = useMemo((): InputStatus => {
    if (
      documentSetStatus === Status.ExportFailed &&
      !selectedCategory?.value &&
      required
    ) {
      return "error";
    }
    return "";
  }, [documentSetStatus, selectedCategory, required]);

  useEffect(() => {
    if (!search?.prefetch) {
      return;
    }

    if (initialOptions?.length) {
      return;
    }

    const controller = new AbortController();

    handleHttpRequest(controller.signal);

    return () => {
      controller.abort();
    };
  }, [handleHttpRequest, initialOptions, search]);

  return {
    tabId,
    selectedCategory,
    options,
    handleChange,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
  };
};

export default useCategorization;
