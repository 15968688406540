import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import lodash from "lodash";

const getInputTypes = (state: RootState) =>
  state.documentTypesReducer.inputTypes;
const getMetricsFilter = (state: RootState) =>
  state.dataRefiningReducer.metricsFilter;

const inputNamesSelector = createSelector(
  [getInputTypes, getMetricsFilter],
  (inputTypes, metricsFilter): Array<string> => {
    if (!inputTypes.length) {
      return [];
    }

    if (metricsFilter.inputIds.length) {
      return lodash
        .chain(inputTypes)
        .filter((i) => metricsFilter.inputIds.includes(i.id))
        .orderBy((i) => i.displayName.toLowerCase())
        .map((i) => i.displayName)
        .value();
    }

    return lodash
      .chain(inputTypes)
      .orderBy((i) => i.displayName.toLowerCase())
      .map((i) => i.displayName)
      .value();
  }
);

export default inputNamesSelector;
