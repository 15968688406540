import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import { ITextVertex } from "../../annotator/interfaces/textLayer";

export interface ITextLayerForPage {
  text: string;
  tokens: Array<ITextVertex>;
}

type Props = {
  page: number;
};

const getPdf = (state: RootState) => state.documentReducer.pdf;
const getPage = (_: RootState, props: Props) => props.page;

const textLayerForPageSelector = createSelector(
  [getPdf, getPage],
  (pdf, page): ITextLayerForPage | null => {
    if (!pdf) {
      return null;
    }

    const { text, pages } = pdf;

    const found = pages.find((p) => p.page === page);

    if (!found) {
      return null;
    }

    const { tokens } = found;

    if (!tokens.length) {
      return null;
    }

    return {
      text: text.slice(0, tokens[tokens.length - 1].textEndIndex),
      tokens,
    };
  }
);

export default textLayerForPageSelector;
