import React, { FC, useCallback, useState } from "react";
import { Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import useMemberModal from "../../hooks/useMemberModal";
import "./AddMemberModal.scss";
import useTableHeaderFilter from "../../hooks/useTableHeaderFilter";
import { FilterType } from "../../interfaces/table";

const AddMemberModal: FC = () => {
  const { t } = useTranslation("userManagement");

  const [email, setEmail] = useState("");

  const { open, changeModalOpen, handleInviteMember } = useMemberModal();
  const { value, setValue, options } = useTableHeaderFilter(FilterType.sources);

  const handleOk = useCallback(async () => {
    const permissions = Array.isArray(value) ? value : [value];
    await handleInviteMember(email, permissions);
    setEmail("");
    setValue([]);
  }, [email, handleInviteMember, value, setValue]);

  const handleChange = useCallback((event: any) => {
    setEmail(event.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setEmail("");
    setValue([]);
    changeModalOpen();
  }, [changeModalOpen, setValue]);

  return (
    <Modal
      centered
      title={t("addModal.header")}
      okText={t("addModal.button")}
      open={open}
      onOk={handleOk}
      onCancel={handleClose}
      className="add-member-modal__container"
    >
      <div className="add-member-modal__body">
        <h4>Member email:</h4>
        <Input
          size="large"
          placeholder="email@example.com"
          value={email}
          onChange={handleChange}
        />
        <div />
        <h4>Member permissions:</h4>
        <Select
          mode={"multiple"}
          size="large"
          value={value}
          options={options}
          placeholder={"Search"}
          onChange={setValue}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          allowClear
        />
      </div>
    </Modal>
  );
};

export default AddMemberModal;
