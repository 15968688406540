import React, { FC } from "react";
import { GraphLineMetrics } from "../../../interfaces/documentSetMetrics";
import lodash from "lodash";
import { SOURCE_COLORS } from "../../../constants";
import "./AverageNumber.scss";
import { useAppSelector } from "../../../../app";
import inputNamesSelector from "../../../../documentTypes/selectors/inputNamesSelector";

type Props = {
  metrics: Array<GraphLineMetrics> | undefined;
  valueSuffix?: string;
};

const AverageNumber: FC<Props> = ({ metrics, valueSuffix }) => {
  const inputNames = useAppSelector(inputNamesSelector);

  if (!metrics) {
    return null;
  }

  const groupedAverage = lodash
    .chain(metrics)
    .orderBy((m) => m.name.toLowerCase())
    .groupBy("name")
    .map((entries, name) => [
      name,
      lodash.meanBy(entries, (entry) => entry.value),
    ])
    .fromPairs()
    .value();

  const seriesNames = lodash.uniq(metrics.map((m) => m.name)).sort();
  const colors = inputNames
    .map((input, index) => {
      if (
        seriesNames.map((sn) => sn.toLowerCase()).includes(input.toLowerCase())
      ) {
        return SOURCE_COLORS[index];
      }

      return null;
    })
    .filter((color): color is string => color !== null);

  if (!Object.keys(groupedAverage).length) {
    return null;
  }

  return (
    <div className="average-number__container">
      <ul className="average-number__list">
        {Object.keys(groupedAverage).map((key, index) => {
          const data = groupedAverage[key] || 0;
          const color = colors[index];
          return (
            <li className="average-number__item" key={key} style={{ color }}>
              <span className="average-number__item-key">{key}</span>
              <span className="average-number__item-value">
                {lodash.round(data, 2)}
                {valueSuffix}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AverageNumber;
