import { useCallback, useMemo } from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { DEFAULT_BLANK_VALUE } from "../constants";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import {
  FilterArrayAction,
  updateFilterArray,
} from "../../common/dataRefining/store/dataRefiningSlice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnItem } from "../interfaces/overview";

const useShowBlankEntriesCheckbox = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  values: Array<string> | string | undefined
) => {
  const dispatch = useAppDispatch();

  const { metadata } = columnItem;

  const {
    enabled: showBlankEntriesCheckbox = false,
    blankValue: newBlankValue = null,
    blankValueLabel = null,
  } = metadata?.showBlankEntriesCheckbox || {};

  const blankValue = useMemo(() => {
    return newBlankValue ?? DEFAULT_BLANK_VALUE;
  }, [newBlankValue]);

  const handleChangeBlankEntries = useCallback(
    (event: CheckboxChangeEvent) => {
      let updateCalls: Array<FilterArrayAction>;

      if (event.target.checked) {
        updateCalls = buildFilterUpdateCalls(
          [blankValue],
          metadata,
          overviewType
        );
      } else {
        updateCalls = buildFilterUpdateCalls([], metadata, overviewType);
      }

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [dispatch, metadata, overviewType, blankValue]
  );

  const checkboxValue = useMemo(() => {
    return (
      (showBlankEntriesCheckbox &&
        Array.isArray(values) &&
        values?.length === 1 &&
        values[0] === blankValue) ||
      values === blankValue
    );
  }, [values, showBlankEntriesCheckbox, blankValue]);

  const labelCheckbox = useMemo(() => {
    const label = blankValueLabel ?? newBlankValue;
    return `Show only ${label ?? "blank"} entries`;
  }, [newBlankValue, blankValueLabel]);

  return {
    checkboxValue,
    handleChangeBlankEntries,
    showBlankEntriesCheckbox,
    blankValue,
    labelCheckbox,
  };
};

export default useShowBlankEntriesCheckbox;
