import { useCallback, useMemo, useRef } from "react";
import useTableUpdater from "./useTableUpdater";
import useMouse from "@react-hook/mouse-position";
import { Rectangle } from "../interfaces/textLayer";
import { Point } from "../interfaces/point";
import { useAppSelector } from "../../app";
import textLayerForPageSelector from "../../annotation/selectors/textlayerForPageSelector";

const EMPTY_RECTANGLE: Rectangle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  rotation: 0,
};

const EMPTY_POINT: Point = {
  x: 0,
  y: 0,
};

const useTableBorder = (
  type: "top" | "left",
  tableId: string,
  isCreatingTable: boolean,
  width: number,
  height: number,
  page: number
) => {
  const textLayerForPage = useAppSelector((state) =>
    textLayerForPageSelector(state, { page })
  );

  const { addRow, addColumn } = useTableUpdater();

  const ref = useRef(null);
  const mouse = useMouse(ref);

  // columns top: style.top, left: style.left + mouse.x
  // rows top: style.top, left: style.left + mouse.y
  const icon = useMemo((): [Point, Rectangle] => {
    if (!isCreatingTable) {
      return [EMPTY_POINT, EMPTY_RECTANGLE];
    }

    const { x, y } = mouse;
    if (x === null || y === null) {
      return [EMPTY_POINT, EMPTY_RECTANGLE];
    }

    if (type === "top") {
      return [
        { x: x - 20 / 2 - 2, y: -9 },
        { left: x, top: 9, width: 2, height, rotation: 0 },
      ];
    }

    return [
      { x: -9, y: y - 20 / 2 - 2 },
      { left: 9, top: y, width, height: 2, rotation: 0 },
    ];
  }, [isCreatingTable, mouse, type, width, height]);

  const addTableDetails = useCallback(() => {
    if (!textLayerForPage) {
      return;
    }

    const { text, tokens } = textLayerForPage;

    const { x, y } = mouse;

    if (x === null || y === null) {
      return;
    }

    if (type === "top" && isCreatingTable && x) {
      addColumn(tableId, tokens, text, x);
    }
    if (type === "left" && isCreatingTable && y) {
      addRow(tableId, tokens, text, y);
    }
  }, [
    addColumn,
    addRow,
    isCreatingTable,
    tableId,
    type,
    mouse,
    textLayerForPage,
  ]);

  return {
    ref,
    icon,
    addTableDetails,
  };
};

export default useTableBorder;
