import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import {
  EntityAnnotationBatchToBeNormalizedDto,
  EntityAnnotationToBeNormalizedDto,
} from "../../annotation/interfaces/annotation";
import { NormalizedOutput } from "../interfaces/normalization";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const configMapApi = createApi({
  reducerPath: "configMapApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    getNormalizedAnnotation: build.mutation<
      NormalizedOutput,
      EntityAnnotationToBeNormalizedDto
    >({
      query: (entityAnnotationDto) => ({
        url: `normalization/api/v1`,
        body: entityAnnotationDto,
        method: "POST",
      }),
    }),
    getNormalizedAnnotationBatch: build.mutation<
      Array<NormalizedOutput>,
      EntityAnnotationBatchToBeNormalizedDto
    >({
      query: (entityAnnotationsDto) => ({
        url: `normalization/api/v1/batch`,
        body: entityAnnotationsDto,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetNormalizedAnnotationMutation,
  useGetNormalizedAnnotationBatchMutation,
} = configMapApi;
