import React, { FC, ReactNode, useMemo } from "react";
import { Input } from "antd";
import { OverviewType } from "../../../../app";
import { ColumnItem } from "../../../interfaces/overview";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import useInputFilterItem from "../../../hooks/useInputFilterItem";
import ExtraSelectFilter from "./ExtraSelectFilter";

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  placeholder: string;
  isOpen: boolean;
  overviewType: OverviewType;
};

const InputFilterItem: FC<Props> = ({
  columnItem,
  label,
  placeholder,
  isOpen,
  overviewType,
}) => {
  const { metadata } = columnItem;

  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(metadata, overviewType),
      metadata?.filterAble!.delimiter
    );
  }, [getValueFromKey, overviewType, metadata]);

  const {
    checkboxValue,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { ref, selectValue, handleChange } = useInputFilterItem(
    columnItem,
    overviewType,
    isOpen,
    showBlankEntriesCheckbox,
    blankValue,
    values
  );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={checkboxValue}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <Input
        ref={ref}
        value={selectValue}
        className="filter-popover__input"
        placeholder={placeholder}
        onChange={handleChange}
        allowClear
      />
      <ExtraSelectFilter columnItem={columnItem} overviewType={overviewType} />
    </div>
  );
};

export default InputFilterItem;
