import React, { FunctionComponent, useCallback } from "react";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import { useTranslation } from "react-i18next";
import { Translation } from "../../../";
import {
  addHoveredEntities,
  removedHoveredEntities,
} from "../../../../app/store/appSlice";
import { useAppDispatch } from "../../../../app";
import { Tooltip } from "antd";
import GroupBlockTitleActions from "./GroupBlockTitleActions/GroupBlockTitleActions";

type Props = {
  translations: Translation;
  entityIds: Array<string>;
  index: number;
  removeGroupBlockAndAnnotations?: () => void;
  removeAnnotations: () => void;
  removeGroupBlocksForEntityAndAnnotations?: () => void;
  showIndex: boolean;
  showRemoveAnnotationsButton: boolean;
  showRemoveGroupBlockButton?: boolean;
};

const GroupBlockTitle: FunctionComponent<Props> = ({
  translations,
  entityIds,
  index,
  removeGroupBlockAndAnnotations,
  removeAnnotations,
  removeGroupBlocksForEntityAndAnnotations,
  showRemoveAnnotationsButton,
  showIndex,
  showRemoveGroupBlockButton = false,
}) => {
  const dispatch = useAppDispatch();
  const {
    i18n: { language },
  } = useTranslation();

  const { t } = useTranslation("annotationSideControls");

  const handleHoverAction = useCallback(
    (enter: boolean) => {
      const hoveredEntities = entityIds.map((entityId) => ({
        id: entityId,
        index,
      }));
      if (enter) {
        dispatch(addHoveredEntities(hoveredEntities));
      } else {
        dispatch(removedHoveredEntities(hoveredEntities));
      }
    },
    [entityIds, index, dispatch]
  );

  return (
    <div className="group-block-title">
      <div
        className="left-container"
        onMouseEnter={() => handleHoverAction(true)}
        onMouseLeave={() => handleHoverAction(false)}
      >
        <h1 className="group-block-name">
          {getInLanguageOrDefault(translations, language)}{" "}
          {showIndex ? index : null}
        </h1>
        <i className="bi bi-tags group-block__annotations-hover" />
      </div>
      <div className="right-container">
        {showRemoveAnnotationsButton && (
          <Tooltip
            placement="top"
            mouseEnterDelay={0.5}
            title={t(
              "groupBlockTitleActions.deleteAnnotationsInGroupBlock"
            ).replace(
              "%NAME",
              `${getInLanguageOrDefault(translations, language)} ${
                showIndex ? index : ""
              }`
            )}
          >
            <div className="group-block-icon">
              <i className="bi bi-eraser" onClick={removeAnnotations} />
            </div>
          </Tooltip>
        )}
        {showRemoveGroupBlockButton && (
          <GroupBlockTitleActions
            groupBlockName={getInLanguageOrDefault(translations, language)}
            groupBlockIndex={showRemoveGroupBlockButton ? index : null}
            removeGroupBlockAndAnnotations={removeGroupBlockAndAnnotations!}
            removeGroupBlocksForEntityAndAnnotations={
              removeGroupBlocksForEntityAndAnnotations!
            }
          />
        )}
      </div>
    </div>
  );
};

export default GroupBlockTitle;
