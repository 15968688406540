import React, { FC } from "react";
import { Select } from "antd";
import { ColumnItem } from "../../../interfaces/overview";
import useExtraSelectFilter from "../../../hooks/useExtraSelectFilter";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import { OverviewType } from "../../../../app";

type Props = {
  columnItem: ColumnItem;
  overviewType: OverviewType;
};

const ExtraSelectFilter: FC<Props> = ({ columnItem, overviewType }) => {
  const {
    showExtraSelectFilter,
    label,
    placeholder,
    options,
    selectValue,
    handleChange,
  } = useExtraSelectFilter(columnItem, overviewType);

  if (!showExtraSelectFilter) {
    return null;
  }

  return (
    <div className="extra-select-filter-container">
      {label}
      <Select
        value={selectValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onInputKeyDown={handleKeyEvent}
        allowClear
      />
    </div>
  );
};

export default ExtraSelectFilter;
