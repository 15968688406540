import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OverviewType, useAppDispatch, useAppSelector } from "../../../app";
import {
  annotationDoneStatuses,
  annotationTodoStatuses,
  doneStatuses,
  errorStatuses,
  Status,
  todoStatuses,
} from "../../status/status";
import {
  removeStatusFromFilters,
  updateFilterArray,
  updateFilterString,
} from "../../dataRefining/store/dataRefiningSlice";
import { resetRowsToRemove } from "../../../documentSet/store/documentSetSlice";
import useUserSettings from "../../../user/hooks/useUserSettings";
import { getValueFromKeyHelper } from "../utils/utils";

const useStatusSelector = (overviewType: OverviewType) => {
  const { t } = useTranslation("overview");
  const { updateUserFiltersAuth0 } = useUserSettings();

  const { overviewFilterArray, archiveFilterArray } = useAppSelector(
    (state) => state.dataRefiningReducer
  );
  const { user, userSettings } = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const statusOptions = useMemo(() => {
    switch (overviewType) {
      case OverviewType.AnnotationTodo:
        return annotationTodoStatuses
          .filter((status) => status !== Status.Archived)
          .map((status) => ({
            value: status,
            label: t(`status.${status}`),
          }));
      case OverviewType.AnnotationDone:
        return annotationDoneStatuses
          .filter((status) => status !== Status.Archived)
          .map((status) => ({
            value: status,
            label: t(`status.${status}`),
          }));
      case OverviewType.Archived:
        return doneStatuses
          .filter((status) => status !== Status.Archived)
          .map((status) => ({
            value: status,
            label: t(`status.${status}`),
          }));
      case OverviewType.Error:
        return errorStatuses.map((status) => ({
          value: status,
          label: t(`status.${status}`),
        }));
      case OverviewType.Remove:
        return [
          {
            value: Status.Remove,
            label: t("status.REMOVE"),
          },
        ];
      default:
        return todoStatuses.map((status) => ({
          value: status,
          label: t(`status.${status}`),
        }));
    }
  }, [overviewType, t]);

  const handleChange = useCallback(
    async (value: string) => {
      if (overviewType === OverviewType.Archived) {
        const newFilterArray = [
          ...archiveFilterArray.filter(
            (item) => item.split("=")[0] !== "status"
          ),
          `status=${value}`,
        ];
        await updateUserFiltersAuth0(
          user,
          userSettings,
          overviewFilterArray,
          newFilterArray
        );
      } else {
        const newFilterArray = [
          ...overviewFilterArray.filter(
            (item) => item.split("=")[0] !== "status"
          ),
          `status=${value}`,
        ];
        await updateUserFiltersAuth0(
          user,
          userSettings,
          newFilterArray,
          archiveFilterArray
        );
      }

      dispatch(
        updateFilterArray({
          key: "status",
          delimiter: "=",
          value: value,
          overviewType,
        })
      );
      dispatch(updateFilterString(overviewType));
      dispatch(resetRowsToRemove());
    },
    [
      overviewType,
      dispatch,
      archiveFilterArray,
      updateUserFiltersAuth0,
      user,
      userSettings,
      overviewFilterArray,
    ]
  );

  const activeStatusValue = useMemo((): string | undefined => {
    if (overviewType === OverviewType.Archived) {
      return getValueFromKeyHelper(archiveFilterArray, "status", "=");
    }

    return getValueFromKeyHelper(overviewFilterArray, "status", "=");
  }, [overviewFilterArray, archiveFilterArray, overviewType]);

  const clearStatusFilter = useCallback(async () => {
    dispatch(removeStatusFromFilters(overviewType));
    dispatch(resetRowsToRemove());
    const newOverviewFilterArray = [
      ...overviewFilterArray.filter((item) => item.split("=")[0] !== "status"),
    ];

    const newArchiveFilterArray = [
      ...archiveFilterArray.filter((item) => item.split("=")[0] !== "status"),
    ];
    await updateUserFiltersAuth0(
      user,
      userSettings,
      newOverviewFilterArray,
      newArchiveFilterArray
    );
  }, [
    dispatch,
    updateUserFiltersAuth0,
    userSettings,
    user,
    overviewFilterArray,
    archiveFilterArray,
    overviewType,
  ]);

  return {
    activeStatusValue,
    handleChange,
    statusOptions,
    clearStatusFilter,
  };
};

export default useStatusSelector;
