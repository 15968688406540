import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import { ColumnItem } from "../interfaces/overview";
import useFocus from "./useFocus";
import { BaseSelectRef } from "rc-select";

const useApiFilterableItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean,
  showBlankEntriesCheckbox: boolean,
  blankValue: string,
  values: Array<string> | undefined,
  fetchDataForSelectedFilters: () => void
) => {
  const dispatch = useAppDispatch();

  const ref: MutableRefObject<null | BaseSelectRef> = useRef(null);

  const {
    open,
    handleBlur: handleAutoBlur,
    handleFocus,
  } = useFocus(ref, isOpen);

  const selectValue = useMemo(() => {
    if (
      showBlankEntriesCheckbox &&
      values?.length === 1 &&
      values[0] === blankValue
    ) {
      return null;
    }

    return values;
  }, [values, showBlankEntriesCheckbox, blankValue]);

  const handleChange = useCallback(
    (value: string | Array<string>) => {
      const updateCalls = buildFilterUpdateCalls(
        value,
        columnItem.metadata,
        overviewType
      );

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [dispatch, columnItem.metadata, overviewType]
  );

  useEffect(() => {
    fetchDataForSelectedFilters();
  }, [fetchDataForSelectedFilters]);

  return {
    ref,
    selectValue,
    handleChange,
    handleFocus,
    handleAutoBlur,
    open,
  };
};

export default useApiFilterableItem;
