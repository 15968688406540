import React, { FC } from "react";
import { PIE_GRAPH_HEIGHT } from "../../../../constants";
import { Skeleton } from "antd";
import "./lineSkeletonLoader.scss";

export const LineSkeletonLoader: FC = () => {
  return (
    <div className="graph__container" style={{ height: PIE_GRAPH_HEIGHT + 20 }}>
      <Skeleton paragraph={false} className="title-line" active />
      <Skeleton paragraph={false} className="filters-line" active />
      <div className="graph-lines__container">
        <Skeleton paragraph={false} className="graph-line-width" active />
        <Skeleton
          paragraph={false}
          className="graph-line-width graph-margin-top"
          active
        />
        <Skeleton
          paragraph={false}
          className="graph-line-width graph-margin-top"
          active
        />
        <Skeleton
          paragraph={false}
          className="graph-line-width graph-margin-top"
          active
        />
      </div>
    </div>
  );
};
