import React, { FC, useCallback } from "react";
import useTableUpdater from "../../../../hooks/useTableUpdater";
import { useAppSelector } from "../../../../../app";
import textLayerForPageSelector from "../../../../../annotation/selectors/textlayerForPageSelector";

type Props = {
  tableId: string;
  rowId: string;
  excluded: boolean;
  isCreatingTable: boolean;
  top: number;
  page: number;
};

const ExcludeRowButton: FC<Props> = ({
  tableId,
  rowId,
  excluded,
  isCreatingTable,
  top,
  page,
}) => {
  const textLayerForPage = useAppSelector((state) =>
    textLayerForPageSelector(state, { page })
  );

  const { excludeRow } = useTableUpdater();

  const handleClick = useCallback(() => {
    if (!textLayerForPage) {
      return;
    }

    const { tokens, text } = textLayerForPage;

    excludeRow(tableId, tokens, text, rowId, !excluded);
  }, [excludeRow, excluded, tableId, rowId, textLayerForPage]);

  if (!isCreatingTable) {
    return null;
  }

  if (excluded) {
    return (
      <div
        className="exclude-button__container"
        style={{ top: top + 5 }}
        onClick={handleClick}
      >
        <span className="bi bi-eye-fill" />
      </div>
    );
  }

  return (
    <div
      className="exclude-button__container"
      style={{ top: top + 5 }}
      onClick={handleClick}
    >
      <span className="bi bi-eye-slash-fill" />
    </div>
  );
};

export default ExcludeRowButton;
