import { useMemo } from "react";
import { EntityAnnotation } from "../../annotator/interfaces/annotation";

const useEntityAnnotation = (
  entityAnnotation: EntityAnnotation | undefined
) => {
  const value = useMemo(() => {
    return entityAnnotation?.value ?? "";
  }, [entityAnnotation]);

  const normalizedValue = useMemo(() => {
    return entityAnnotation?.entityAnnotationNormalization?.normalizedValue;
  }, [entityAnnotation?.entityAnnotationNormalization]);

  const options = useMemo(() => {
    return entityAnnotation?.entityAnnotationNormalization?.options;
  }, [entityAnnotation?.entityAnnotationNormalization]);

  const hasNormalizedValue = useMemo(
    () => !!normalizedValue,
    [normalizedValue]
  );

  return {
    value,
    normalizedValue,
    options,
    hasNormalizedValue,
  };
};

export default useEntityAnnotation;
