import React, { FC, useCallback, useEffect } from "react";
import { useAppDispatch } from "../../../../app";
import {
  updateMetricsFilterArray,
  updateMetricsFilterString,
} from "../../../../common/dataRefining/store/dataRefiningSlice";
import { Select } from "antd";
import SelectEmptyState from "../../../../metadata/components/categorization/SelectEmptyState";
import { MetricFilterItem } from "../../../interfaces/documentSetMetrics";
import useFilteringSearch from "../../../../documentSet/hooks/useFilteringSearch";
import useMetricsFilterItem from "../../../hooks/useMetricsFilterItem";
import { triggerRefreshMetrics } from "../../../store/analyticsSlice";
import { METRIC_FILTER_ITEM_DELIMITER } from "../../../constants";

type Props = {
  filterItem: MetricFilterItem;
  placeholder: string;
  loading: boolean;
  mode?: "multiple";
};

const ApiFilterableItem: FC<Props> = ({
  filterItem,
  placeholder,
  loading,
  mode,
}) => {
  const { metadata, key } = filterItem;

  const dispatch = useAppDispatch();

  const { options, handleSearch, handleFilter, handleBlur } =
    useFilteringSearch(metadata?.filterAble!.query, filterItem.inputs);

  const { fetchDataForSelectedFilters, selectedFiltersInfo, getValueFromKey } =
    useMetricsFilterItem(filterItem);

  useEffect(() => {
    fetchDataForSelectedFilters();
  }, [fetchDataForSelectedFilters]);

  const handleChange = useCallback(
    (value: string | Array<string>) => {
      if (Array.isArray(value)) {
        dispatch(
          updateMetricsFilterArray({
            key,
            delimiter: METRIC_FILTER_ITEM_DELIMITER,
            value: value.join("+"),
            overviewType: null,
          })
        );
        dispatch(updateMetricsFilterString());
        dispatch(triggerRefreshMetrics());
        return;
      }

      dispatch(
        updateMetricsFilterArray({
          key,
          delimiter: METRIC_FILTER_ITEM_DELIMITER,
          value: value,
          overviewType: null,
        })
      );
      dispatch(updateMetricsFilterString());
      dispatch(triggerRefreshMetrics());
    },
    [dispatch, key]
  );

  return (
    <div className="filter-item">
      <Select
        className="filter-item__select"
        showSearch
        allowClear
        value={getValueFromKey(
          metadata?.filterAble!.filterKey,
          METRIC_FILTER_ITEM_DELIMITER,
          selectedFiltersInfo
        )?.split("+")}
        placeholder={placeholder}
        onSearch={handleSearch}
        notFoundContent={<SelectEmptyState />}
        onChange={handleChange}
        onBlur={handleBlur}
        mode={mode}
        filterOption={handleFilter}
        options={options}
        disabled={loading}
      />
    </div>
  );
};

export default ApiFilterableItem;
