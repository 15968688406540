import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { OverviewType } from "../../../../app";
import { Select } from "antd";
import SelectEmptyState from "../../../../metadata/components/categorization/SelectEmptyState";
import { ColumnItem } from "../../../interfaces/overview";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import useFilteringSearch from "../../../hooks/useFilteringSearch";
import useApiFilterableItem from "../../../hooks/useApiFilterableItem";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import ExtraSelectFilter from "./ExtraSelectFilter";

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  placeholder: string;
  mode?: "multiple";
  renderRef: MutableRefObject<any>;
  isOpen: boolean;
  overviewType: OverviewType;
};

const ApiFilterableItem: FC<Props> = ({
  columnItem,
  label,
  placeholder,
  mode,
  renderRef,
  isOpen,
  overviewType,
}) => {
  const { options, handleSearch, handleFilter, handleBlur } =
    useFilteringSearch(columnItem?.metadata?.filterAble!.query);

  const { fetchDataForSelectedFilters, selectedFiltersInfo, getValueFromKey } =
    useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter,
      selectedFiltersInfo
    )?.split("+");
  }, [
    getValueFromKey,
    columnItem?.metadata,
    overviewType,
    selectedFiltersInfo,
  ]);

  const {
    checkboxValue,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { ref, selectValue, handleChange, handleFocus, handleAutoBlur, open } =
    useApiFilterableItem(
      columnItem,
      overviewType,
      isOpen,
      showBlankEntriesCheckbox,
      blankValue,
      values,
      fetchDataForSelectedFilters
    );

  return (
    <div className="filter-popover__item">
      <label className="filter-popover__label">{label}</label>
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={checkboxValue}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <Select
        ref={ref}
        showSearch
        allowClear
        value={selectValue}
        placeholder={placeholder}
        onSearch={handleSearch}
        notFoundContent={<SelectEmptyState />}
        onChange={handleChange}
        onBlur={() => {
          handleBlur();
          handleAutoBlur();
        }}
        onFocus={handleFocus}
        open={open}
        mode={mode}
        filterOption={handleFilter}
        options={options}
        getPopupContainer={() => renderRef.current}
        onInputKeyDown={handleKeyEvent}
      />
      <ExtraSelectFilter columnItem={columnItem} overviewType={overviewType} />
    </div>
  );
};

export default ApiFilterableItem;
