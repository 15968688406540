import React, { FC } from "react";
import { Checkbox } from "antd";
import { DocumentSetData } from "../../../interfaces/documentSet";
import useBulkRemoveHeader from "../../../hooks/useBulkRemoveHeader";
import "./BulkRemoveHeader.scss";

type Props = {
  documentSets: Array<DocumentSetData>;
};
const BulkRemoveHeader: FC<Props> = ({ documentSets }) => {
  const { rowsToRemove, isAllSelected, toggleSelectAll } =
    useBulkRemoveHeader(documentSets);

  return (
    <div className="bulk-remove-header__container">
      {rowsToRemove.length >= 1 && (
        <Checkbox
          indeterminate={!isAllSelected && rowsToRemove.length >= 1}
          checked={isAllSelected}
          className="checkbox"
          disabled={rowsToRemove.length < 1}
          onClick={toggleSelectAll}
        />
      )}
    </div>
  );
};

export default BulkRemoveHeader;
