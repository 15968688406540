import React from "react";
import useBulkRemove from "../../hooks/useBulkRemove";
import { Alert } from "antd";
import "./BulkRemoveAlert.scss";
import { HEIGHT_BULK_REMOVE_HEADER } from "../../constants";
import { useTranslation } from "react-i18next";

const BulkRemoveAlert = () => {
  const { removeRows, rowsToRemove, resetRows } = useBulkRemove();

  const { t } = useTranslation("overview");

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      {rowsToRemove.length >= 1 && (
        <Alert
          className="bulk-remove-header"
          style={{ height: `${HEIGHT_BULK_REMOVE_HEADER}px` }}
          message={
            <div className="bulk-remove-header-container">
              <div></div>
              <div>
                <span className="description">
                  {rowsToRemove.length}{" "}
                  {rowsToRemove.length > 1
                    ? t("bulkRemoveAlert.descriptionMultiple")
                    : t("bulkRemoveAlert.description")}
                </span>
                <a className="link" onClick={removeRows}>
                  {t("bulkRemoveAlert.removeLink")}
                </a>
              </div>
              <div>
                <a className="link" onClick={resetRows}>
                  {t("bulkRemoveAlert.cancelLink")}
                </a>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default BulkRemoveAlert;
