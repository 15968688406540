import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { DatePicker } from "antd";
import { ColumnItem } from "../../../interfaces/overview";
import useDateFilterItem from "../../../hooks/useDateFilterItem";
import { DATE_FILTER_FORMAT } from "../../../constants";
import { OverviewType } from "../../../../app";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import useRangePickerFocus from "../../../hooks/useRangePickerFocus";

const { RangePicker } = DatePicker;

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  renderRef: MutableRefObject<any>;
  overviewType: OverviewType;
};

const DateFilterItem: FC<Props> = ({
  columnItem,
  label,
  renderRef,
  overviewType,
}) => {
  const { handleFocus, open, setOpen } = useRangePickerFocus();

  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter
    );
  }, [columnItem?.metadata, getValueFromKey, overviewType]);

  const {
    checkboxValue,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { onChange, rangeDates } = useDateFilterItem(
    columnItem,
    overviewType,
    showBlankEntriesCheckbox,
    blankValue,
    values,
    setOpen
  );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={checkboxValue}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <RangePicker
        open={open}
        className="filter-item range-picker"
        format={DATE_FILTER_FORMAT}
        value={rangeDates}
        allowClear
        onChange={onChange}
        onCalendarChange={onChange}
        allowEmpty={[false, true]}
        getPopupContainer={() => renderRef.current}
        onFocus={handleFocus}
      />
    </div>
  );
};

export default DateFilterItem;
