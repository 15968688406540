import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import { triggerRefreshMetrics } from "../../../analytics/store/analyticsSlice";
import { setMetricsIsBySource } from "../../dataRefining/store/dataRefiningSlice";

const useMetricsModeSelector = () => {
  const { isBySource } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const { loading } = useAppSelector((state) => state.analyticsReducer.metrics);

  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (isBySource: boolean) => {
      dispatch(setMetricsIsBySource(isBySource));
      dispatch(triggerRefreshMetrics());
    },
    [dispatch]
  );

  return {
    isBySource,
    handleChange,
    loading,
  };
};

export default useMetricsModeSelector;
