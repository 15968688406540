import { ButtonType, useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect, useMemo } from "react";
import {
  changeActiveButton,
  changeActiveEntity,
  changeAnnotating,
  changeEditing,
} from "../../app/store/appSlice";
import useKeyPressedListener from "../../common/utilities/hooks/useKeyPressedListener";
import { GroupBlockEntityType } from "../";
import selectCategorizations from "../../metadata/selectors/selectCategorizations";
import { Status } from "../../common/status/status";
import flatGroupBlockWrapperListSelector from "../selectors/flatGroupBlockWrapperListSelector";
import { GroupBlocksWrapper } from "../interfaces/groupblock";
import { triggerGroupBlockTabCheck } from "../../annotationTableControls/store/AnnotationTableControlsSlice";

const TAB = "TAB";
const SHIFT = "SHIFT";
const ARROW_LEFT = "ARROWLEFT";
const ARROW_RIGHT = "ARROWRIGHT";
const ARROW_UP = "ARROWUP";
const ARROW_DOWN = "ARROWDOWN";

enum Direction {
  UP,
  DOWN,
}

enum TabType {
  ENTITY,
  BUTTON,
  SELECT,
}

type TabItem = {
  type: TabType;
  value: GroupBlockEntityType | ButtonType;
  index: number;
  isTableControls?: boolean;
  columnIndex?: number;
  groupBlockId?: string;
};

const hasReachedLimit = (
  direction: Direction,
  activeTabItem: number,
  tabList: Array<TabItem>
) => {
  if (activeTabItem === -1) {
    return false;
  }

  if (direction === Direction.UP) {
    return activeTabItem === 0;
  }

  return activeTabItem === tabList.length - 1;
};

const useTab = (hideNonRequiredEntities: boolean, disabled: boolean) => {
  const dispatch = useAppDispatch();
  const { keyPressed: shiftPressed } = useKeyPressedListener(SHIFT);

  const { activeEntity, activeButton } = useAppSelector(
    (state) => state.appReducer
  );

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const { singleLineGroupBlocks, multipleLinesGroupBlocks } =
    useAppSelector<GroupBlocksWrapper>((state) =>
      flatGroupBlockWrapperListSelector(state, { hideNonRequiredEntities })
    );

  const categorizations = useAppSelector((state) =>
    selectCategorizations(state)
  );

  const { groupBlockCount } = useAppSelector(
    (state) => state.configMapReducer.groupBlock
  );

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const tabList = useMemo((): Array<TabItem> => {
    const singleLineGroupBlocksTabs = singleLineGroupBlocks.flatMap(
      (groupBlock) => {
        const inCounts = groupBlockCount.find(
          (c) => c.groupBlockId === groupBlock.id
        );
        if (inCounts) {
          return [
            ...[...Array(inCounts.count)].flatMap((_, index) => {
              const updatedTabList: Array<TabItem> = [];
              groupBlock.categorizationGroupBlocks.forEach((categorization) => {
                updatedTabList.push({
                  type: TabType.BUTTON,
                  value: {
                    id: `categorization-${categorization.id}-${groupBlock.id}-${
                      index + 1
                    }`,
                  },
                  index: 0,
                });
              });
              if (activeDocumentSet?.status !== Status.Error) {
                groupBlock.groupBlockEntityTypes.forEach((entity) => {
                  updatedTabList.push({
                    type: TabType.ENTITY,
                    value: entity,
                    index: index + 1,
                  });
                });
              }
              return updatedTabList;
            }),
          ];
        }

        if (activeDocumentSet?.status !== Status.Error) {
          return groupBlock.groupBlockEntityTypes.map((entity) => ({
            type: TabType.ENTITY,
            value: entity,
            index: 1,
          }));
        }

        return [];
      }
    );

    const multipleLinesGroupBlocksTabs = multipleLinesGroupBlocks.flatMap(
      (groupBlock) => {
        const inCounts = groupBlockCount.find(
          (c) => c.groupBlockId === groupBlock.id
        );
        if (inCounts) {
          return [
            ...[...Array(inCounts.count)].flatMap((_, index) => {
              const updatedTabList: Array<TabItem> = [];
              let columnIndex = 0;

              groupBlock.categorizationGroupBlocks.forEach((categorization) => {
                updatedTabList.push({
                  type: TabType.SELECT,
                  value: {
                    id: `categorization-${categorization.id}-${groupBlock.id}-${
                      index + 1
                    }`,
                  },
                  index: 0,
                  isTableControls: true,
                  columnIndex,
                  groupBlockId: groupBlock.id,
                });
                columnIndex++;
              });
              if (activeDocumentSet?.status !== Status.Error) {
                groupBlock.groupBlockEntityTypes.forEach((entity) => {
                  updatedTabList.push({
                    type: TabType.ENTITY,
                    value: entity,
                    index: index + 1,
                    isTableControls: true,
                    columnIndex,
                    groupBlockId: groupBlock.id,
                  });
                  columnIndex++;
                });
              }
              updatedTabList.push({
                type: TabType.BUTTON,
                value: {
                  id: `group-block-button-add-${groupBlock.id}-${index + 1}`,
                },
                index: index + 1,
                isTableControls: true,
                columnIndex,
                groupBlockId: groupBlock.id,
              });
              return updatedTabList;
            }),
          ];
        }

        if (activeDocumentSet?.status !== Status.Error) {
          return groupBlock.groupBlockEntityTypes.map((entity, index) => ({
            type: TabType.ENTITY,
            value: entity,
            index: 1,
            isTableControls: true,
            columnIndex: index,
          }));
        }

        return [];
      }
    );

    const categorizationList: Array<TabItem> = categorizations.map(
      (categorization) => ({
        type: TabType.SELECT,
        value: {
          id: `categorization-${categorization.id}-${1}`,
        },
        index: 1,
      })
    );

    if (isAnnotationMode) {
      return [
        ...categorizationList,
        ...singleLineGroupBlocksTabs,
        ...multipleLinesGroupBlocksTabs,
        {
          type: TabType.BUTTON,
          value: { id: "action-hold" },
          index: 0,
        },
        {
          type: TabType.BUTTON,
          value: { id: "action-approve" },
          index: 0,
        },
      ];
    }

    return [
      ...categorizationList,
      ...singleLineGroupBlocksTabs,
      ...multipleLinesGroupBlocksTabs,
      {
        type: TabType.BUTTON,
        value: { id: "action-reject" },
        index: 0,
      },
      {
        type: TabType.BUTTON,
        value: { id: "action-hold" },
        index: 0,
      },
      {
        type: TabType.BUTTON,
        value: { id: "action-approve" },
        index: 0,
      },
    ];
  }, [
    groupBlockCount,
    singleLineGroupBlocks,
    multipleLinesGroupBlocks,
    isAnnotationMode,
    categorizations,
    activeDocumentSet?.status,
  ]);

  const getActiveTabIndex = useCallback(() => {
    if (!activeEntity && !activeButton) {
      return -1;
    }

    if (activeEntity) {
      return tabList.findIndex((tabItem) => {
        if ([TabType.BUTTON, TabType.SELECT].includes(tabItem.type)) {
          return false;
        }

        const value: GroupBlockEntityType =
          tabItem.value as GroupBlockEntityType;
        return (
          activeEntity.entityType.id === value.entityType.id &&
          activeEntity.index === tabItem.index
        );
      });
    }

    return tabList.findIndex((tabItem) => {
      if (tabItem.type === TabType.ENTITY) {
        return false;
      }

      const value: ButtonType = tabItem.value as ButtonType;
      return activeButton!.id === value.id;
    });
  }, [activeEntity, activeButton, tabList]);

  const getActiveTab = useCallback(() => {
    if (!activeEntity && !activeButton) {
      return undefined;
    }

    if (activeEntity) {
      return tabList.find((tabItem) => {
        if ([TabType.BUTTON, TabType.SELECT].includes(tabItem.type)) {
          return false;
        }

        const value: GroupBlockEntityType =
          tabItem.value as GroupBlockEntityType;
        return (
          activeEntity.entityType.id === value.entityType.id &&
          activeEntity.index === tabItem.index
        );
      });
    }

    return tabList.find((tabItem) => {
      if (tabItem.type === TabType.ENTITY) {
        return false;
      }

      const value: ButtonType = tabItem.value as ButtonType;
      return activeButton!.id === value.id;
    });
  }, [activeEntity, activeButton, tabList]);

  const setActiveTabItem = useCallback(
    (tabItem: TabItem) => {
      switch (tabItem.type) {
        case TabType.ENTITY: {
          const value: GroupBlockEntityType =
            tabItem.value as GroupBlockEntityType;
          dispatch(changeAnnotating(true));
          dispatch(changeActiveEntity({ ...value, index: tabItem.index }));
          dispatch(changeActiveButton(undefined));
          dispatch(changeEditing(null));
          break;
        }
        case TabType.SELECT: {
          const value: ButtonType = tabItem.value as ButtonType;
          dispatch(changeAnnotating(false));
          dispatch(changeActiveEntity(undefined));
          dispatch(changeActiveButton({ ...value }));
          dispatch(changeEditing(value.id));
          break;
        }
        default: {
          const value: ButtonType = tabItem.value as ButtonType;
          dispatch(changeAnnotating(false));
          dispatch(changeActiveEntity(undefined));
          dispatch(changeActiveButton({ ...value }));
          dispatch(changeEditing(null));
        }
      }
    },
    [dispatch]
  );

  const tabHandler = useCallback(
    (event: KeyboardEvent) => {
      if (disabled) {
        return;
      }

      const { key } = event;
      if (key.toUpperCase() !== TAB) {
        return;
      }

      event.preventDefault();
      const direction = shiftPressed ? Direction.UP : Direction.DOWN;

      const activeTabItem = getActiveTabIndex();

      let nextTabItem;

      if (hasReachedLimit(direction, activeTabItem, tabList)) {
        if (direction === Direction.UP) {
          nextTabItem = tabList.length - 1;
        } else {
          nextTabItem = 0;
        }
      } else {
        if (activeTabItem === -1) {
          nextTabItem = direction === Direction.UP ? tabList.length - 1 : 0;
        } else {
          const offset = direction === Direction.UP ? -1 : 1;
          nextTabItem = activeTabItem + offset;
        }
      }

      const tabItem = tabList[nextTabItem];
      dispatch(triggerGroupBlockTabCheck());

      setActiveTabItem(tabItem);
    },
    [
      getActiveTabIndex,
      dispatch,
      shiftPressed,
      tabList,
      disabled,
      setActiveTabItem,
    ]
  );

  const arrowKeysHandler = useCallback(
    (event: KeyboardEvent) => {
      if (disabled) {
        return;
      }

      const { key } = event;
      if (
        key.toUpperCase() !== ARROW_LEFT &&
        key.toUpperCase() !== ARROW_RIGHT &&
        key.toUpperCase() !== ARROW_UP &&
        key.toUpperCase() !== ARROW_DOWN
      ) {
        return;
      }

      event.preventDefault();

      const activeTab = getActiveTab();

      if (
        !activeTab ||
        activeTab.isTableControls === undefined ||
        activeTab.columnIndex === undefined ||
        activeTab.type === TabType.SELECT
      ) {
        return;
      }

      let toFindRowIndex = -1;
      let toFindColumnIndex = -1;

      switch (key.toUpperCase()) {
        case ARROW_LEFT:
          toFindRowIndex = activeTab.index;
          toFindColumnIndex = activeTab.columnIndex - 1;
          break;
        case ARROW_RIGHT:
          toFindRowIndex = activeTab.index;
          toFindColumnIndex = activeTab.columnIndex + 1;
          break;
        case ARROW_UP:
          toFindRowIndex = activeTab.index - 1;
          toFindColumnIndex = activeTab.columnIndex;
          break;
        case ARROW_DOWN:
          toFindRowIndex = activeTab.index + 1;
          toFindColumnIndex = activeTab.columnIndex;
          break;
      }

      const nextTabItem = tabList.find((tabItem) => {
        return (
          tabItem.groupBlockId === activeTab.groupBlockId &&
          tabItem.index === toFindRowIndex &&
          tabItem.columnIndex === toFindColumnIndex
        );
      });

      if (!nextTabItem) {
        return;
      }

      setActiveTabItem(nextTabItem);
    },
    [tabList, disabled, getActiveTab, setActiveTabItem]
  );

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      tabHandler(event);
      arrowKeysHandler(event);
    },
    [tabHandler, arrowKeysHandler]
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [downHandler]);
};

export default useTab;
