import React, { FC, useEffect, useRef } from "react";
import { Select } from "antd";
import useCategorization from "../../hooks/useCategorization";
import { getInLanguageOrDefault } from "../../../common/utilities/language";
import { useTranslation } from "react-i18next";
import CategoryVisibleValue from "./CategoryVisibleValue";
import SelectEmptyState from "./SelectEmptyState";
import { useAppSelector } from "../../../app";
import { BaseSelectRef } from "rc-select";
import { CategorizationWithConfig } from "../../../configMap/interfaces/category";

type Props = {
  disabled: boolean;
  categorizationWithConfig: CategorizationWithConfig;
  index: number;
  groupBlockId?: string;
};

const { Option } = Select;

const mapping = { value: "key" };

const Categorization: FC<Props> = ({
  categorizationWithConfig,
  disabled,
  index,
  groupBlockId,
}) => {
  const ref = useRef<BaseSelectRef | null>(null);
  const { label } = categorizationWithConfig.categorization;

  const { activeButton, isAnnotating } = useAppSelector(
    (state) => state.appReducer
  );

  const {
    tabId,
    handleChange,
    selectedCategory,
    options,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
  } = useCategorization(categorizationWithConfig, index, groupBlockId);

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (!activeButton || !ref.current) {
      return;
    }

    if (activeButton.id !== tabId) {
      return;
    }

    ref.current!.focus();
  }, [activeButton, tabId]);

  useEffect(() => {
    if (isAnnotating) {
      ref.current!.blur();
    }
  }, [isAnnotating]);

  return (
    <div className="classifier-item__container">
      <div className="classifier-item__data-wrapper">
        <div className="classifier-item__label-wrapper">
          <label>{`${getInLanguageOrDefault(label, language)} ${
            categorizationWithConfig.required ? "*" : ""
          }`}</label>
          <CategoryVisibleValue visibleValue={selectedCategory?.visibleValue} />
        </div>
      </div>
      <Select
        open={disableTyping}
        ref={ref}
        allowClear
        showSearch
        value={selectedCategory?.value}
        placeholder="Select a value"
        optionFilterProp="children"
        onChange={(value) => handleChange(value, ref.current!.blur)}
        onFocus={handleTypingChange}
        onBlur={handleTypingChange}
        onSearch={handleSearch}
        filterOption={handleFilter}
        notFoundContent={<SelectEmptyState />}
        fieldNames={mapping}
        disabled={disabled}
        className={disabled ? "classifier-item__select-disabled" : ""}
        status={status}
      >
        {options.map((o) => {
          const { label, value, key } = o;
          const split = label.split(/\n/);

          if (split.length > 1) {
            const height = split.length * 25;
            return (
              <Option
                key={key}
                value={value}
                label={label}
                style={{ height: `${height}px` }}
              >
                <ul>
                  {split.map((s) => (
                    <li key={s}>{s}</li>
                  ))}
                </ul>
              </Option>
            );
          }

          return (
            <Option key={key} value={value} label={label}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default Categorization;
