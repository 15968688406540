import React, { FC, ReactNode, useCallback, useMemo } from "react";
import { useActiveDocumentSet } from "../../../documentSet";
import { Button, notification } from "antd";
import { Status } from "../../../common/status/status";
import { ButtonType } from "../../../common";
import { calculateHideDelay } from "../../../common/utilities/text";
import { useAppSelector } from "../../../app";
import { postData } from "../../../configMap/utils/entityUtils";
import { useIdleTimer } from "react-idle-timer";
import { throttle } from "../../../common/utilities/func";
import documentIdsSelector from "../../../document/selectors/documentIdsSelector";
import useRouting from "../../hooks/useRouting";
import highestGroupBlockCountSelector from "../../../configMap/selectors/highestGroupBlockCountSelector";
import numberOfUserAnnotationsSelector from "../../../annotation/selectors/numberOfUserAnnotationsSelector";
import { useSaveMetricEventMutation } from "../../../analytics/store/analyticsApi";
import selectAnnotationReducer from "../../../annotation/selectors/annotationReducerSelector";

type Props = {
  id: string;
  status: Status;
  message: string;
  buttonText: string;
  buttonType: ButtonType;
  icon?: ReactNode;
  disabled?: boolean;
  setIsLoading: (loading: boolean) => void;
  openUserConflictModal?: () => void;
};

const ActionButton: FC<Props> = ({
  id,
  status,
  message,
  buttonText,
  buttonType,
  setIsLoading,
  icon,
  disabled = false,
  openUserConflictModal,
}) => {
  const { getTotalIdleTime, getTotalActiveTime } = useIdleTimer({
    timeout: 5000,
    debounce: 500,
  });

  const user = useAppSelector((state) => state.userReducer.user);
  const entityAnnotations = useAppSelector(selectAnnotationReducer);

  const tableAnnotations = useAppSelector(
    (state) => state.annotationReducer.tableAnnotations
  );
  const { documentCategoryAnnotations } = useAppSelector(
    (state) => state.metadataReducer.categorizationState
  );
  const activeButton = useAppSelector((state) => state.appReducer.activeButton);
  const numberOfLines = useAppSelector(highestGroupBlockCountSelector);
  const numberOfAnnotations = useAppSelector(numberOfUserAnnotationsSelector);
  const { numberOfAdditions, numberOfUpdates, numberOfDeletions } =
    useAppSelector((state) => state.analyticsReducer);

  const documentIds = useAppSelector(documentIdsSelector);

  const { updateAssigneeAndStatusWithExceptionHandling, activeDocumentSet } =
    useActiveDocumentSet();

  const handleRouting = useRouting(buttonType);

  const [saveMetricEvent] = useSaveMetricEventMutation();

  // eslint-disable-next-line
  const handleApproval = useCallback(
    throttle(async () => {
      if (activeDocumentSet?.documents) {
        setIsLoading(true);

        try {
          await postData(
            documentIds,
            tableAnnotations,
            entityAnnotations,
            documentCategoryAnnotations
          );
          await updateAssigneeAndStatusWithExceptionHandling(
            activeDocumentSet,
            status,
            user!
          );

          await saveMetricEvent({
            documentSetId: activeDocumentSet.id,
            organizationName: user?.organization.name ?? "",
            activeTimeSeconds: getTotalActiveTime() / 1000,
            idleTimeSeconds: getTotalIdleTime() / 1000,
            numberOfAdditions,
            numberOfDeletions,
            numberOfUpdates,
            numberOfLines,
            numberOfAnnotations,
          }).unwrap();

          notification.success({
            message: "Success",
            description: message,
            duration: calculateHideDelay(message),
            onClose: () => {
              handleRouting(() => setIsLoading(false));
            },
          });
        } catch (error: any) {
          if (error?.status === 409 && openUserConflictModal) {
            openUserConflictModal();
          } else {
            notification.error({
              message: "Error",
              description: error.message,
              duration: calculateHideDelay(error.message),
            });
          }
        } finally {
          setIsLoading(false);
        }
      }
    }, 250),
    [
      documentCategoryAnnotations,
      user,
      numberOfDeletions,
      numberOfAdditions,
      numberOfUpdates,
      activeDocumentSet,
      setIsLoading,
      tableAnnotations,
      updateAssigneeAndStatusWithExceptionHandling,
      status,
      message,
      entityAnnotations,
      getTotalActiveTime,
      getTotalIdleTime,
      saveMetricEvent,
      numberOfLines,
      numberOfAnnotations,
      documentIds,
      handleRouting,
      openUserConflictModal,
    ]
  );

  const className = useMemo(() => {
    if (activeButton?.id === id) {
      return "btn-focused";
    }

    return "";
  }, [activeButton, id]);

  return (
    <Button
      id={id}
      icon={icon}
      className={className}
      onClick={handleApproval}
      type={buttonType === ButtonType.Approve ? "primary" : "default"}
      ghost={buttonType !== ButtonType.Approve}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};

export default ActionButton;
