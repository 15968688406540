import React, { FC, useMemo } from "react";
import "./LineGraph.scss";
import { LineChart } from "@opd/g2plot-react";
import { GraphLineMetrics } from "../../../interfaces/documentSetMetrics";
import { LINE_GRAPH_HEIGHT, SOURCE_COLORS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";
import ToolTip from "./Tooltip/Tooltip";
import { GraphEvent } from "../../../interfaces/graph";
import { LineSkeletonLoader } from "./lineSkeletonLoader/lineSkeletonLoader";
import { Tooltip } from "antd";
import { useAppSelector } from "../../../../app";
import inputNamesSelector from "../../../../documentTypes/selectors/inputNamesSelector";
import lodash from "lodash";

type Props = {
  title: string;
  tooltipTitle: string;
  metrics: Array<GraphLineMetrics> | undefined;
  loading: boolean;
  isTimeGraph?: boolean;
  lineGraphRef?: React.MutableRefObject<any>;
  handleLegendClick?: (e: any) => void;
  tooltipSuffix?: string;
  xField?: string;
  yField?: string;
  seriesField?: string;
};

const LineGraph: FC<Props> = ({
  title,
  tooltipTitle,
  metrics,
  loading,
  isTimeGraph = false,
  lineGraphRef,
  handleLegendClick,
  tooltipSuffix,
  xField = "createdDate",
  yField = "value",
  seriesField = "name",
}) => {
  const {
    i18n: { language },
  } = useTranslation("analytics");

  const inputNames = useAppSelector(inputNamesSelector);
  const isBySource = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter.isBySource
  );

  const seriesNames = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return lodash
      .chain(metrics)
      .map("name")
      .uniq()
      .sortBy((name) => name.toLowerCase())
      .value();
  }, [metrics]);

  const colors = useMemo(() => {
    if (isBySource) {
      return inputNames
        .map((input, index) => {
          if (
            seriesNames
              .map((sn) => sn.toLowerCase())
              .includes(input.toLowerCase())
          ) {
            return SOURCE_COLORS[index];
          }

          return null;
        })
        .filter((color): color is string => color !== null);
    }

    return SOURCE_COLORS;
  }, [inputNames, seriesNames, isBySource]);

  if (loading) {
    return <LineSkeletonLoader />;
  }

  if (!metrics?.length) {
    return null;
  }

  return (
    <div className="graph__container">
      <div className="graph__header">
        <h3>{title} </h3>
        <Tooltip title={tooltipTitle}>
          <span className="bi bi-question-circle" />
        </Tooltip>
      </div>
      <LineChart
        chartRef={lineGraphRef}
        data={loading ? [] : metrics}
        xField={xField}
        yField={yField}
        seriesField={seriesField}
        height={LINE_GRAPH_HEIGHT}
        color={colors}
        smooth={false}
        legend={{ position: "top" }}
        point={{ size: 3 }}
        meta={{
          name: {
            alias: "Name",
            formatter: (name) => name,
            values: seriesNames, // This controls the order of series
          },
        }}
        tooltip={{
          customContent: (title, data) =>
            renderToStaticMarkup(
              <ToolTip
                title={title}
                data={data}
                isTime={isTimeGraph}
                language={language}
                suffix={tooltipSuffix}
              />
            ),
        }}
        onReady={(c) => {
          c.on("legend:click", (e: GraphEvent) => {
            if (handleLegendClick) {
              handleLegendClick(e);
            }
          });
        }}
      />
    </div>
  );
};

export default LineGraph;
