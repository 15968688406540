import React, { FC } from "react";
import "./SourcesOverview.scss";
import { numberAbbreviation } from "../../utils/number";
import { getClassNameForTrend } from "../../utils/trend";
import SourceDistribution from "./SourceDistribution";
import { shortEnglishHumanizer } from "../../utils/time";
import { Tooltip } from "antd";
import { SOURCE_COLORS } from "../../constants";
import { NumberMetrics } from "../../interfaces/documentSetMetrics";
import { useTranslation } from "react-i18next";
import useSourceFilter from "../../hooks/useSourceFilter";

type Props = {
  sourceMetrics: Array<NumberMetrics>;
};

const SourcesOverview: FC<Props> = ({ sourceMetrics }) => {
  const { t } = useTranslation("analytics");
  const { setInputFilterByName } = useSourceFilter();

  return (
    <div className="sources-overview__container">
      <div className="sources-overview__header">
        <h3>{t("metricSources.title")}</h3>
        <Tooltip title={t("metricSources.tooltip")}>
          <span className="bi bi-question-circle" />
        </Tooltip>
      </div>
      <div className="sources-overview__data-container">
        <div className="sources-overview__header-container">
          <h5>{t("metricSources.source")}</h5>
          <h5>{t("metricSources.volume")}</h5>
          <h5>{t("metricSources.avgTime")}</h5>
          <h5>
            <span style={{ color: "#1971c2" }}>
              {t("metricSources.manual")}
            </span>
            /
            <span style={{ color: "#2f9e44" }}>
              {t("metricSources.automatic")}
            </span>
            /
            <span style={{ color: "#e03131" }}>
              {t("metricSources.rejected")}
            </span>
          </h5>
        </div>
        {sourceMetrics.map((s, index) => (
          <div key={s.source} className="sources-overview__source-item">
            <span
              style={{ color: SOURCE_COLORS[index] }}
              className="sources-overview__source-title"
              onClick={() => setInputFilterByName(s.source)}
            >
              {s.source}
            </span>
            <div className="sources-overview__source-item-double">
              <span className="main">
                {numberAbbreviation.format(s.volume.value)}
              </span>
              <span
                className={getClassNameForTrend(
                  s.volume.trend,
                  "sources-overview__source-item-trend"
                )}
              >
                {s.volume.trend}
              </span>
            </div>
            <div className="sources-overview__source-item-double">
              <span className="main">
                {shortEnglishHumanizer(s.avgTimeSpend.value * 1000)}
              </span>
              <span
                className={getClassNameForTrend(
                  s.avgTimeSpend.trend,
                  "sources-overview__source-item-trend",
                  true
                )}
              >
                {s.avgTimeSpend.trend}
              </span>
            </div>
            <SourceDistribution
              manual={s.manual}
              automatic={s.automatic}
              rejected={s.rejected}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourcesOverview;
