import React, { FC, useCallback } from "react";
import useTableUpdater from "../../../../hooks/useTableUpdater";
import { useAppSelector } from "../../../../../app";
import textLayerForPageSelector from "../../../../../annotation/selectors/textlayerForPageSelector";

type Props = {
  visible: boolean;
  tableId: string;
  top: number;
  left: number;
  page: number;
};

const LinkTableButton: FC<Props> = ({ visible, tableId, top, left, page }) => {
  const textLayerForPage = useAppSelector((state) =>
    textLayerForPageSelector(state, { page })
  );

  const { linkTables } = useTableUpdater();

  const handleClick = useCallback(() => {
    if (!textLayerForPage) {
      return;
    }

    const { tokens, text } = textLayerForPage;

    linkTables(tableId, tokens, text);
  }, [linkTables, tableId, textLayerForPage]);

  if (!visible) {
    return null;
  }

  return (
    <div
      className="link-table-button__container"
      style={{ top, left }}
      onClick={handleClick}
    >
      <i className="bi bi-link-45deg link-table-button__icon" />
    </div>
  );
};

export default LinkTableButton;
