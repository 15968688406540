import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import { ColumnItem } from "../interfaces/overview";
import useFocus from "./useFocus";
import { InputRef } from "antd";

const useInputFilterItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean,
  showBlankEntriesCheckbox: boolean,
  blankValue: string,
  values: string | undefined
) => {
  const dispatch = useAppDispatch();

  const ref: MutableRefObject<null | InputRef> = useRef(null);

  useFocus(ref, isOpen);

  const selectValue = useMemo(() => {
    if (showBlankEntriesCheckbox && values === blankValue) {
      return "";
    }

    return values;
  }, [values, showBlankEntriesCheckbox, blankValue]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const updateCalls = buildFilterUpdateCalls(
        value,
        columnItem?.metadata,
        overviewType
      );

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [dispatch, columnItem?.metadata, overviewType]
  );

  return {
    ref,
    selectValue,
    handleChange,
  };
};

export default useInputFilterItem;
