import { MutableRefObject, useEffect, useState } from "react";
import { InputRef } from "antd";
import { BaseSelectRef } from "rc-select";

const useFocus = (
  ref: MutableRefObject<null | InputRef | BaseSelectRef>,
  isOpen: boolean
) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isOpen) {
      ref.current.focus();
    } else {
      ref.current.blur();
    }
  }, [isOpen, ref]);

  const handleBlur = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  return { open, handleBlur, handleFocus };
};

export default useFocus;
