import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguage } from "../../app";
import {
  CategorizationState,
  DocumentCategoryAnnotation,
  DocumentCategoryAnnotationRemove,
  DocumentCategoryAnnotationRemoveByCategorizations,
  DocumentCategoryAnnotationRemoveByGroupBlockIndex,
  UpdateMultiGroupBlockDocumentCategoryAnnotation,
} from "../../configMap/interfaces/category";
import { equals } from "../utils/documentCategoryAnnotation";

interface MetadataState {
  categorizationState: CategorizationState;
  language: SupportedLanguage;
}

const initialState: MetadataState = {
  categorizationState: {
    documentCategoryAnnotations: [],
  },
  language: "en",
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setMetadataLanguage: (state, action: PayloadAction<SupportedLanguage>) => {
      state.language = action.payload;
    },
    setDocumentCategoryAnnotationsBatch: (
      state,
      action: PayloadAction<Array<DocumentCategoryAnnotation>>
    ) => {
      state.categorizationState.documentCategoryAnnotations = action.payload;
    },
    updateDocumentCategoryAnnotation: (
      state,
      action: PayloadAction<DocumentCategoryAnnotation>
    ) => {
      const { documentCategoryAnnotations } = state.categorizationState;
      const { payload } = action;

      const found = documentCategoryAnnotations.find((dca) =>
        equals(dca, payload)
      );

      if (found) {
        state.categorizationState.documentCategoryAnnotations =
          documentCategoryAnnotations.map((dca) => {
            if (equals(dca, payload)) return payload;

            return dca;
          });
        return;
      }

      state.categorizationState.documentCategoryAnnotations = [
        ...documentCategoryAnnotations,
        payload,
      ];
    },
    removeDocumentCategoryAnnotation: (
      state,
      action: PayloadAction<DocumentCategoryAnnotationRemove>
    ) => {
      const { documentCategoryAnnotations } = state.categorizationState;
      const { payload } = action;

      const found = documentCategoryAnnotations.find((dca) =>
        equals(dca, payload)
      );

      if (found) {
        state.categorizationState.documentCategoryAnnotations =
          documentCategoryAnnotations.filter((dca) => {
            return !equals(dca, payload);
          });
      }
    },
    modifyDocumentCategoryAnnotationsByGroupBlockIndexInState: (
      state,
      action: PayloadAction<DocumentCategoryAnnotationRemoveByGroupBlockIndex>
    ) => {
      const {
        documentId,
        categorizations,
        groupBlockIndex,
        isGroupBlockRemoved,
      } = action.payload;
      const categorizationIds = categorizations.map(
        (categorization) => categorization.id
      );

      state.categorizationState.documentCategoryAnnotations =
        state.categorizationState.documentCategoryAnnotations
          .filter(
            (dca) =>
              !(
                dca.documentId === documentId &&
                categorizationIds.includes(dca.categorizationId) &&
                dca.index === groupBlockIndex
              )
          )
          .map((dca) => {
            if (!isGroupBlockRemoved) {
              return dca;
            }

            if (
              dca.documentId === documentId &&
              categorizationIds.includes(dca.categorizationId) &&
              dca.index! > groupBlockIndex
            ) {
              return {
                ...dca,
                index: dca.index! - 1,
              };
            }
            return dca;
          });
    },
    removeDocumentCategoryAnnotationsByCategorizationsInState: (
      state,
      action: PayloadAction<DocumentCategoryAnnotationRemoveByCategorizations>
    ) => {
      const { documentId, categorizations } = action.payload;
      const categorizationIds = categorizations.map(
        (categorization) => categorization.id
      );

      state.categorizationState.documentCategoryAnnotations =
        state.categorizationState.documentCategoryAnnotations.filter(
          (dca) =>
            !(
              dca.documentId === documentId &&
              categorizationIds.includes(dca.categorizationId)
            )
        );
    },
    updateGroupBlockDocumentCategoryAnnotationsInState: (
      state,
      action: PayloadAction<UpdateMultiGroupBlockDocumentCategoryAnnotation>
    ) => {
      const { groupBlockIndex, categorizations, addBefore } = action.payload;
      const categorizationIds = categorizations.map(
        (categorization) => categorization.id
      );

      state.categorizationState.documentCategoryAnnotations =
        state.categorizationState.documentCategoryAnnotations.map((dca) => {
          if (!dca.index) {
            return dca;
          }
          if (
            addBefore &&
            dca.index - 1 === 0 &&
            categorizationIds.includes(dca.categorizationId)
          ) {
            return { ...dca, index: dca.index + 1 };
          }
          if (
            dca.index - 1 > groupBlockIndex &&
            categorizationIds.includes(dca.categorizationId)
          ) {
            return { ...dca, index: dca.index + 1 };
          }
          return dca;
        });
    },
    resetDocumentCategoryAnnotationsInState: (state) => {
      state.categorizationState.documentCategoryAnnotations = [];
    },
    resetMetadataReducer: () => initialState,
  },
});

export const {
  setMetadataLanguage,
  resetMetadataReducer,
  updateDocumentCategoryAnnotation,
  removeDocumentCategoryAnnotation,
  modifyDocumentCategoryAnnotationsByGroupBlockIndexInState,
  removeDocumentCategoryAnnotationsByCategorizationsInState,
  updateGroupBlockDocumentCategoryAnnotationsInState,
  resetDocumentCategoryAnnotationsInState,
  setDocumentCategoryAnnotationsBatch,
} = metadataSlice.actions;

export default metadataSlice.reducer;
