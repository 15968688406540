import { useCallback, useMemo, useState } from "react";
import { getValueFromKeyHelper } from "../../common/menu/utils/utils";
import { useAppSelector } from "../../app";
import { fetchClassifierOptionsSearch } from "../../metadata/utils/classifier";
import { OptionType } from "../../metadata/interfaces/classifier";
import { MetricFilterItem } from "../interfaces/documentSetMetrics";
import { METRIC_FILTER_ITEM_DELIMITER } from "../constants";

const useMetricsFilterItem = (filterItem: MetricFilterItem) => {
  const { filterArray } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const [selectedFiltersInfo, setSelectedFiltersInfo] = useState<
    Array<OptionType>
  >([]);

  const getValueFromKey = useCallback(
    (
      key: string,
      separator: string,
      itemList?: Array<OptionType>
    ): string | undefined =>
      getValueFromKeyHelper(filterArray, key, separator, itemList),
    [filterArray]
  );

  const hasFilter = useMemo(() => {
    if (!filterItem?.metadata?.filterAble?.filterKey) {
      return false;
    }

    return (
      getValueFromKey(
        filterItem.metadata?.filterAble.filterKey,
        METRIC_FILTER_ITEM_DELIMITER
      ) !== undefined
    );
  }, [filterItem, getValueFromKey]);

  const fetchDataForSelectedFilters = useCallback(async () => {
    if (!filterItem.metadata) {
      return;
    }

    const {
      key,
      metadata: {
        delimiter,
        filterAble: { query },
      },
    } = filterItem;

    const items = getValueFromKeyHelper(filterArray, key, delimiter)?.split(
      "+"
    );

    if (!items?.length) {
      return;
    }

    const value = `${query.infoParam}=${items.join(",")}`;

    try {
      const { data } = await fetchClassifierOptionsSearch(
        `${query.endpoint}/info`,
        value
      );
      setSelectedFiltersInfo(data);
    } catch (e) {
      console.log(e);
    }
  }, [filterItem, filterArray]);

  return {
    getValueFromKey,
    hasFilter,
    selectedFiltersInfo,
    fetchDataForSelectedFilters,
  };
};

export default useMetricsFilterItem;
